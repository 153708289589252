import React, { useState, useEffect } from "react";
import BotIcon from "./../../assets/BotIcon.svg";
import cookie from "react-cookies";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ReplayIcon from "@mui/icons-material/Replay";
import { useAudioPlayer } from "react-use-audio-player";

export default function BotMessage({
  fetchMessage,
  playOnce,
  audio,
  msgsRef,
  botIndex,
  fetchAudio,
  setDisabled
}) {
  const [isLoading, setLoading] = useState(
    botIndex >= 0 && msgsRef.current[botIndex] ? false : true
  );
  const [message, setMessage] = useState(
    botIndex >= 0 && msgsRef.current[botIndex] ? msgsRef.current[botIndex] : ""
  );
  const profile = cookie.load("profile");
  const [play, setPlay] = useState(false);
  const [donePlaying, setDonePlaying] = useState(false);
  const getVolume = () => localStorage.getItem("hr-volume");
  const audioPlayer = useAudioPlayer();

  const stopAllAudio = () => {
    if (audio.current && audio.current.length) {
      audio.current.forEach((item) => {
        if (item) {
          item?.audioPlayer?.pause();
          item?.setPlay(false);
        }
      });
    }
  };
  useEffect(() => {
    async function loadMessage() {
      const msg = await fetchMessage();
      if (msg) {
        if (playOnce) {
          audioPlayer.load(
            "https://myy-audio-bucket.s3.eu-north-1.amazonaws.com/audio_files/response_.mp3",
            {
              autoplay: true,
            }
          );
          audioPlayer.setVolume(getVolume());
          audioPlayer.play();
          audio.current.push({
            audioPlayer,
            setPlay: (params) => setPlay(params),
          });
        } else {
          const res2 = await fetchAudio(msg);
          if (res2) {
            const blob = new Blob([res2], { type: "audio/mp3" });
            const url = URL.createObjectURL(blob);
            const blobWithExtension = `${url}#t=0.001&.mp3`;
            audioPlayer.load(blobWithExtension, {
              onplay: () => {
                setPlay(true);
              },
              onend: () => {
                setPlay(false);
              },
            });
            audioPlayer.setVolume(getVolume());
            audioPlayer.play();
            audio.current.push({
              audioPlayer,
              setPlay: (params) => setPlay(params),
            });
          }
        }

        setLoading(false);
        setDisabled(false)
        setMessage(msg);
        msgsRef.current.push(msg)
      }
    }
    botIndex >= 0 && !msgsRef.current[botIndex] && loadMessage();
  }, [fetchMessage]);

  return (
    <div className="message-container">
      <div className="alignItem">
        <img
          src={BotIcon}
          width={32}
          height={32}
          alt="Bot Icon"
          className="bot-icon"
        />
        <div
          className="bot-message"
          style={{ padding: isLoading && "3px 14px" }}
        >
          {isLoading ? (
            <div className="loader-container">
              <div className="loader-head">
                please wait while we're fetching details{" "}
              </div>
              <div className="stage">
                <div className="dot-pulse"></div>
              </div>
            </div>
          ) : message ===
            `Hey ${profile?.givenName} ${profile?.familyName}, How can I help you?` ? (
            message
          ) : (
            <div style={{ display: "flex" }}>
              <div>
                {message}
                {/* {!playOnce && (
                  <>
                    <br />
                    <br />
                    "For more info, refer to the"&nbsp;&nbsp;
                    <a className="anchor"
                      target="_blank"
                      href="https://docs.google.com/document/d/1eHSTDeOn4VarU-cZjxNaH8HxxsZJLHJR-BYRR3d_ktg/edit"
                    >
                      HR Manual
                    </a>
                  </>
                )} */}
                {!playOnce && !donePlaying && (
                  <div className="audio-container">
                    <div>
                      <span>
                        {play === false ? (
                          <span
                            title="Play Audio"
                            className="play-pauseBtn"
                            onClick={() => {
                              stopAllAudio();
                              setPlay(true);
                              audioPlayer.setVolume(getVolume());
                              audioPlayer.play();
                            }}
                          >
                            <PlayArrowIcon className="icon" />
                          </span>
                        ) : (
                          <span
                            title="Pause Audio"
                            className="play-pauseBtn"
                            onClick={() => {
                              setPlay(false);
                              audioPlayer.pause();
                            }}
                          >
                            <PauseIcon className="icon" />
                          </span>
                        )}
                      </span>
                      <span
                        title="Replay Audio"
                        className="play-pauseBtn"
                        onClick={() => {
                          stopAllAudio();
                          setPlay(true);
                          audioPlayer.stop();
                          audioPlayer.setVolume(getVolume());
                          audioPlayer.play();
                        }}
                      >
                        <ReplayIcon className="icon" />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
