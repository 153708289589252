import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import Modal from "@mui/material/Modal";
import BotMessage from "./componentsForChat/BotMessage";
import UserMessage from "./componentsForChat/UserMessage";
import Messages from "./componentsForChat/Messages";
import Input from "./componentsForChat/Input";
import CloseIcon from "@mui/icons-material/Close";
import API from "./ChatbotAPI";
import BotIcon from "./../assets/BotIcon.svg";
import Chatbot from "./../assets/FM LOGO.svg";
import "./chat.css";
import { notifyError } from "./componentsForChat/notify";
import AudioLoading from "./componentsForChat/AudioLoding";
import Mute from "./../assets/mute-icon.svg";
import Unmute from "./../assets/unmute-icon.svg";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import waves from "./../assets/waves.gif";
import SendIcon from "@mui/icons-material/Send";
import RecorderTimer from "./componentsForChat/RecorderTimer";
import CancelRecording from "./../assets/cross-icon.svg";
import { RxCross2 } from "react-icons/rx";

const FoxMatrixChatbot = (props) => {
  const [messages, setMessages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [recording, setRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(null);
  const [text2, setText2] = useState("");
  const [globaMute, setGlobalUnmute] = useState(1);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const cancelRecRef = useRef(false);
  const audioRef = useRef([]);
  let analyser = null;
  let audioContext = null;
  let silenceTimer = null;
  const transcript = useRef("");
  const recognitionRef = useRef(null);
  const canvasRef = useRef(null);
  const msgsRef = useRef([]);
  const botIndex = useRef(0);
  const analyserRef = useRef(null);
  const countDown = useRef(null);
  let timeoutIds = [];
  const [showAssitancePopUp, setShowAssitancePopUp] = useState(true);
  const [disabled, setDisabled] = useState(false)

  const startRecording = (e) => {
    stopAllAudio();
    if (e) {
      e.preventDefault();
    }
    audioChunksRef.current = [];
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const tempaudioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        audioContext = tempaudioContext;
        const source = tempaudioContext.createMediaStreamSource(stream);
        const gainNode = tempaudioContext.createGain();
        gainNode.gain.value = 1;
        source.connect(gainNode);
        const tmpanalyser = tempaudioContext.createAnalyser();

        if (tmpanalyser) {
          tmpanalyser.fftSize = 2048;
          tmpanalyser.smoothingTimeConstant = 0.8;
        }
        analyser = tmpanalyser;
        analyserRef.current = tmpanalyser;
        gainNode.connect(tmpanalyser);

        let media = new MediaRecorder(stream);
        media.ondataavailable = (e) => {
          audioChunksRef.current.push(e.data);
        };
        media.onstop = async () => {
          if (
            cancelRecRef.current === false &&
            audioChunksRef.current &&
            audioChunksRef.current.length > 0
          ) {
            setDisabled(true)
            countDown.current = null;
            clearAllTimeouts();
            const audioBlob = new Blob(audioChunksRef.current, {
              type: "audio/wav",
            });
            setAudioBlob(audioBlob);
            const formData = new FormData();
            formData.append("audio_file", audioBlob);
            // console.log("formdata in hr manual - ", formData);
            const data = await API.getTextReponse(formData);
            if (data?.response_text !== "\n") {
              displayUserAudioRes(data);
            } else {
              setLoading(false);
            }
          } else {
            audioChunksRef.current = [];
            cancelRecRef.current = false;
          }
        };
        media.start();
        mediaRecorderRef.current = media;
        drawBarWaveform();
        setRecording(true);
        setTrackedTimeout(checkForSilence, 10);
        setLoading(false);
      })
      .catch((err) => {
        setErrorMessage("Error accessing microphone: " + err.message);
      });
  };

  const colors = [
    "rgba(255,255,255)", // white
  ];

  const drawBarWaveform = () => {
    if (!canvasRef.current) {
      setTimeout(() => {
        drawBarWaveform();
      }, 0);
    }

    if (!analyserRef.current || !canvasRef.current) {
      return;
    }

    const canvas = canvasRef.current;
    const canvasContext = canvas.getContext("2d");
    const bufferLength = analyserRef.current.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      analyserRef.current.getByteFrequencyData(dataArray);
      canvasContext.clearRect(0, 0, canvas.width, canvas.height);

      const barWidth = (canvas.width / bufferLength) * 2;
      let barHeight;
      let x = 0;

      const centerY = canvas.height / 2;

      for (let i = 0; i < bufferLength; i++) {
        barHeight = dataArray[i] / 2;

        // Pick a color from the colors array based on bar height
        const colorIndex = Math.floor((barHeight / 128) * colors.length);
        const barColor = colors[colorIndex] || colors[0];

        canvasContext.fillStyle = barColor;

        // Draw bar from the center line up and down
        canvasContext.fillRect(x, centerY - barHeight / 2, barWidth, barHeight);
        x += barWidth + 1;
      }

      requestAnimationFrame(draw);
    };

    draw();
  };

  // Function to add a timeout and track its identifier
  const setTrackedTimeout = (callback, delay) => {
    const timeoutId = setTimeout(callback, delay);
    timeoutIds.push(timeoutId);
    return timeoutId;
  };

  // Function to clear all tracked timeouts
  const clearAllTimeouts = () => {
    timeoutIds.forEach(clearTimeout); // Clear each timeout
    timeoutIds = []; // Reset the array
  };

  const checkForSilence = () => {
    // console.log("checking for silence");
    if (analyserRef.current) {
      const buffer = new Uint8Array(analyserRef.current.fftSize);
      // console.log("Buffer values:", buffer);
      analyserRef.current.getByteFrequencyData(buffer);
      const total = buffer.reduce((acc, val) => acc + val, 0);
      // console.log("total energy -- ", total);
      const isSilent = total < 20000;

      if (isSilent) {
        // console.log("Silence detected, stopping recording in 3 seconds");
        if (!countDown.current) {
          countDown.current = new Date();
        }
        // console.log("timeDiff -- ", new Date().getTime() - new Date(countDown.current).getTime());
        if (
          new Date().getTime() - new Date(countDown.current).getTime() >=
          2700
        ) {
          if (
            mediaRecorderRef.current &&
            mediaRecorderRef.current.state === "recording"
          ) {
            // console.log("stop recording called due to silence");
            stopRecording();
          }
        } else {
          clearAllTimeouts();
          setTrackedTimeout(checkForSilence, 10);
        }
      } else {
        countDown.current = null;
        clearAllTimeouts();
        setTrackedTimeout(checkForSilence, 10);
        // console.log("Resetting silence timer to check for silence in 100ms");
      }
    } else {
      console.error("analyserRef.current node is not initialized.");
    }
  };

  const stopRecording = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
      }
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        setRecording(false);
        if (recognitionRef.current) {
          recognitionRef.current.stop();
        }
        setLoading(true);
      } else {
        notifyError("Something went wrong!");
        // console.error("mediaRecorder is undefined");
      }
    },
    [silenceTimer]
  );

  const handleCloseModal = () => {
    setIsModalOpen(true);
  };
  const handleOpenModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    async function loadWelcomeMessage() {
      handleUnmute();
      botIndex.current++;
      setMessages([
        <BotMessage
          key="0"
          playOnce={true}
          msgsRef={msgsRef}
          botIndex={0}
          audio={audioRef}
          fetchMessage={async () => await API.GetChatbotResponse("hi")}
          fetchAudio={async (text) =>
            await API.getAudioResponse({ response_text: text })
          }
          setDisabled={setDisabled}
        />,
      ]);
    }
    // loadWelcomeMessage();
    return () => {
      if (audioContext) {
        audioContext.close();
      }
    };
  }, []);

  const stopAllAudio = () => {
    if (audioRef.current && audioRef.current.length) {
      audioRef.current.forEach((item) => {
        if (item) {
          item?.audioPlayer?.pause();
          item?.setPlay(false);
        }
      });
    }
  };

  const send = async (text) => {
    setDisabled(true)
    const tempInd = botIndex.current;
    botIndex.current++;
    stopAllAudio();
    const newMessages = messages.concat(
      <UserMessage key={messages.length + 1} text={text} />,
      <BotMessage
        key={messages.length + 2}
        msgsRef={msgsRef}
        botIndex={tempInd}
        audio={audioRef}
        fetchMessage={async () => await API.GetChatbotResponse(text)}
        fetchAudio={() => {}}
        setDisabled={setDisabled}
      />
    );
    setMessages(newMessages);
  };

  const cancelRecording = () => {
    if (mediaRecorderRef.current) {
      countDown.current = null;
      clearAllTimeouts();
      cancelRecRef.current = true;
      audioChunksRef.current = [];
      mediaRecorderRef.current.stop();
      setRecording(false);
    }
  };

  const displayUserAudioRes = (data) => {
    console.log("data -- ", data?.response, data?.response?.response_text)
    const tempInd = botIndex.current;
    botIndex.current++;
    if (data && data?.response?.response_text && data?.response?.response_text !== "\n") {
      let newMessages = [...messages];
      newMessages = newMessages.concat(
        <UserMessage key={messages.length} text={data?.response?.response_text} />,
        <BotMessage
          key={messages.length + 1}
          audio={audioRef}
          msgsRef={msgsRef}
          botIndex={tempInd}
          fetchMessage={async () =>
            await API.GetChatbotResponse(data?.response?.response_text)
          }
          fetchAudio={async (text) =>
            await API.getAudioResponse({ response_text: text })
          }
          setDisabled={setDisabled}
        />
      );
      setLoading(false);
      setMessages(newMessages);
    } else {
      let newMessages = [...messages];
      newMessages = newMessages.concat(
        <UserMessage
          key={messages.length}
          text={"An error occurred while fetching the response."}
        />
      );
      setLoading(false);
      setMessages(newMessages);
    }
  };

  const handleMuteAudio = () => {
    if (audioRef.current && audioRef.current.length) {
      audioRef.current.forEach((item) => {
        if (item) {
          item?.audioPlayer?.mute(true);
        }
      });
    }
  };

  const handleUnMuteAudio = () => {
    if (audioRef.current && audioRef.current.length) {
      audioRef.current.forEach((item) => {
        if (item) {
          item?.audioPlayer?.mute(false);
        }
      });
    }
  };

  const handleMute = () => {
    handleMuteAudio();
    localStorage.setItem("hr-volume", 0);
    setGlobalUnmute(0);
  };

  const handleUnmute = () => {
    handleUnMuteAudio();
    localStorage.setItem("hr-volume", 1);
    setGlobalUnmute(1);
  };

  const handleAssitancePopUp = () => {
    setShowAssitancePopUp(false);
  };

  return (
    <>
      {isModalOpen ? (
        <>
          {showAssitancePopUp && (
            <>
              <span
                className="assistance-cross-container"
                onClick={handleAssitancePopUp}
              >
                <RxCross2 className="assistance-cross" />
              </span>
              <div className="assistance-popup-container">
              <div onClick={handleOpenModal} className="assistance-popup">
                Need Any Assistance ?
              </div>
              </div>
            </>
          )}
          <img
            src={BotIcon}
            alt="img"
            title="Looking for Help"
            className="chatIcon-heHelp-fox"
            onClick={handleOpenModal}
          />
        </>
      ) : (
        <div className="fox-chatbot">
          <div className="fox-header">
            <img src={Chatbot} height={32} width={80} alt="Bot Icon" className="bot-icon" />
            &nbsp;
            <span className="headText"> &nbsp;&nbsp; Chatbot</span>
            {/* <span className="cross2">
              {globaMute ? (
                <span title="Mute Audio" onClick={handleMute}>
                  <VolumeUpIcon className="icon" />
                </span>
              ) : (
                <span title="Unmute Audio" onClick={handleUnmute}>
                  <VolumeOffIcon className="icon" />
                </span>
              )}
            </span> */}
            <span className="cross">
              <CloseIcon onClick={() => handleCloseModal()} />
            </span>
          </div>
          <Messages messages={messages} />
          {!isLoading ? (
            recording ? (
              <div className="recording-container">
                <div className="wave-image">
                  <canvas ref={canvasRef} />
                </div>
                <div className="recording-btn-container">
                  <RecorderTimer />
                  <div
                    onClick={cancelRecording}
                    className="center-container cursor-pointer wave-container"
                  >
                    <RxCross2 className="cancelRecording" />
                  </div>
                  <div className="center-container cursor-pointer">
                    <div className="btn-container-recording">
                      <SendIcon className="send-btn2" onClick={stopRecording} />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <Input
                onSend={send}
                stopRecording={stopRecording}
                startRecording={startRecording}
                cancelRecording={cancelRecording}
                recording={recording}
                transcript={transcript.current}
                text2={text2}
                setText2={setText2}
                disabled={disabled}
                setDisabled={setDisabled}
              />
            )
          ) : (
            <AudioLoading />
          )}
        </div>
      )}
    </>
  );
};

export default FoxMatrixChatbot;
