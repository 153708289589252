import { useState } from "react";
import Dashboard from "./Dashboard/Dashboard";
import ResumeUpload from "./ResumeUpload/ResumeUpload";
import Chat from "./chatbot/chat";
import FoxMatrixChatbot from "./fox-chatbot/foxchat";


function App() {
  const [ resumefileURL, setResumeFileURL] = useState("")
  return (
    <div className="App">
     {!resumefileURL && <ResumeUpload resumefileURL={resumefileURL} setResumeFileURL={setResumeFileURL} />}
     {resumefileURL && <Dashboard resumefileURL={resumefileURL} setResumeFileURL={setResumeFileURL} />}
     <Chat />
     <FoxMatrixChatbot />
    </div>
  );
}

export default App;
