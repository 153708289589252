import React, { useState, useRef } from 'react';
import axios from 'axios';
import './resumeUpload.css';
import { HiOutlineCloudArrowUp } from "react-icons/hi2";
import { RxCross1 } from "react-icons/rx";

const ResumeUpload = ({resumefileURL,setResumeFileURL}) => {
    const [resumeName, setResumeName] = useState("")
    const [resume, setResume] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const fileInputRef = useRef(null);

    const handleFileChange = (e) => {
        setResumeName(e.target.files[0]?.name?.split(".")[0])
        setResume(e.target.files[0]);
    };

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!resume) {
            alert('Please select a resume file first.');
            return;
        }

        const formData = new FormData();
        formData.append("name", resumeName)
        formData.append('resume_file', resume);

        try {
            const response = await axios.post("https://intquestion.service.innovationm.com/resume_app/resume_upload/", formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 201) {
                setResumeFileURL(response?.data?.file_url)
                setSuccessMessage('Resume uploaded successfully!');
                setResume(null);
            }
        } catch (error) {
            console.error('Error uploading resume:', error);
            setSuccessMessage('Failed to upload resume. Please try again.');
        }
    };

    const handleRemoveResume = () => {
        setResumeName("")
        setResume(null)
    }

    return (
        <div className="resume-upload-container">
            <h2>Upload Your Resume</h2>
            {!resumeName && <div className="image-upload" onClick={handleUploadClick}>
                <HiOutlineCloudArrowUp className='file-upload-icon' />
                <p>Click to upload resume</p>
            </div>}
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            {resumeName && (<div className='resume-container'><div className="image-upload" style={{padding: "11px"}}>{resumeName} </div><div onClick={handleRemoveResume} title='Remove resume'><RxCross1 className='resume-cross' /></div></div>)}
            <button className="upload-button" onClick={handleSubmit}>
                Submit
            </button>
            {successMessage && <div className="success-message">{successMessage}</div>}
        </div>
    );
};

export default ResumeUpload;
