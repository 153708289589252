import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './dashboard.css';

const Dashboard = ({resumefileURL, setResumeFileURL}) => {
  const [technologyOptions, setTechnologyOptions] = useState(["Python", 
    "Django",
    "Flask",
    "Java", 
    "React"]);
  const [response, setResponse] = useState('');
  const [error, setError] = useState('');
  const [openSummaryModal, setOpenSummaryModal] = useState(false)
  const [openProjectModal, setOpenProjectModal] = useState(false)
  const [openTechnologyModal, setOpenTechnologyModal] = useState(false)
  const [summary, setSummary] = useState("")
  const [technology, setTechnology] = useState("")
  const [projectRelated, setProjectRelated] = useState("")
  const [loading, setLoading] = useState({
    summary: false,
    technology: false,
    project: false
  })

  useEffect(() => {
    return () => {
      setOpenProjectModal(false)
      setOpenSummaryModal(false)
      setOpenTechnologyModal(false)
      setSummary(null)
      setTechnology(null)
      setProjectRelated(null)
    }
  }, [])

  const handleSummaryClick = async () => {
    setOpenSummaryModal(true)
    setLoading(prev => ({...prev, summary: true}))
    try {
      const res = await axios.post("https://intquestion.service.innovationm.com/resume_app/resume/", {"category":["Summary"],
      "skills" : [],
      "file_url": resumefileURL});
      setSummary(res?.data?.questions?.Summary)
      setError('');
      setLoading(prev => ({...prev, summary: false}))
    } catch (err) {
      setError('Failed to fetch summary data');
      setResponse('');
      setLoading(prev => ({...prev, summary: false}))
    }
  };

  const handleTechnologyClick = async (option) => {
    setOpenTechnologyModal(true)
    setLoading(prev => ({...prev, technology: true}))
    try {
      const res = await axios.post("https://intquestion.service.innovationm.com/resume_app/resume/", {"category":["Technical"],
      "skills" : [option],
      "file_url": resumefileURL});
      setTechnology(res?.data?.questions?.Technical);
      setError('');
      setLoading(prev => ({...prev, technology: false}))
    } catch (err) {
      setError('Failed to fetch technology data');
      setResponse('');
      setLoading(prev => ({...prev, technology: false}))
    }
  };

  const handleProjectRelatedClick = async () => {
    setOpenProjectModal(true)
    setLoading(prev => ({...prev, project: true}))
    try {
      const res = await await axios.post("https://intquestion.service.innovationm.com/resume_app/resume/", {"category":["Project Related"],
      "skills" : [],
      "file_url": resumefileURL});
      setProjectRelated(res?.data?.questions["Project Related"]);
      setError('');
    setLoading(prev => ({...prev, project: false}))
    } catch (err) {
      setError('Failed to fetch project related data');
      setResponse('');
    setLoading(prev => ({...prev, project: false}))
    }
  };

  const handleBack = () => {
    setResumeFileURL("")
  }

  return (
    <div className="dashboard-container">
      <div className="buttons-container">
        <button className="action-button" onClick={handleSummaryClick}>Summary</button>

        <div className="dropdown">
          <button className="dropdown-button">Technology</button>
          <div className="dropdown-content">
            {technologyOptions.map((option, index) => (
              <div key={index} className="dropdown-item" onClick={() => handleTechnologyClick(option)}>
                {option}
              </div>
            ))}
          </div>
        </div>

        <button className="action-button" onClick={handleProjectRelatedClick}>Project Related</button>
        <button className="back-button" onClick={handleBack}>Back</button>
      </div>

      {error && <div className="error-message">{error}</div>}
      {(openSummaryModal || openTechnologyModal || openProjectModal) && (<div className="response-container">
        {openSummaryModal && <div className='summary-container'>
        <div className='fox-heading'>Summary</div>
          {!loading?.summary ? <div className='fox-body'>{summary && summary[0]}</div> : <div className='fox-body'>Loading...</div>}
          </div>}
        {openTechnologyModal && <div className='tech-container'>
        <div className='fox-heading'>Technical Skills</div>
          {!loading?.technology ? <div className='fox-body'>{technology && technology?.map((item, index) => <><div key={index}>{item}</div><br /></>)}</div> :  <div className='fox-body'>Loading...</div>}
          </div>}
        {openProjectModal && <div className='project-container'>
        <div className='fox-heading'>Project Related</div>
          {!loading?.project ? <div className='fox-body'>{projectRelated && projectRelated?.map((item, index) => <><div key={index}>{item}</div><br /></>)}</div> : <div className='fox-body'>Loading...</div>}
          </div>}
      </div>)}
    </div>
  );
};

export default Dashboard;
